<template>
  <div class="filter">
    <div class="filter__btn-block">
      <div class="filter__btn-left">
        <div class="filter__btn-left__label">{{ $t('Filter') }}</div>
        <div class="filter__btn-left__reset-btn" @click="resetFilter">
          {{ $t('Clear') }}
        </div>
      </div>
      <div @click="closeLeftSidebar">
        <zem-icon class="cursor-pointer" name="close" size="18" />
      </div>
    </div>
    <div class="filter__dropdown">
      <div class="zem-dropdown mb-0">
        <label class="filter__label tw-mt-2" for="date">Воронка</label>
        <ZemDropdownList
          v-model="selectedItems.funnel"
          :options="funnelsList"
          class="mt-0"
          @change="handlerFilter('pipeline_id', $event)"
        />
      </div>
      <div class="zem-dropdown mb-0">
        <label class="filter__label tw-mt-2" for="date">Этап</label>
        <ZemDropdownList
          :disabled="!selectedItems.funnel.value"
          v-model="selectedItems.status"
          :options="statusesList.filter(el => el.parent === selectedItems.funnel.value)"
          class="mt-0"
          @change="handlerFilter('status_id', $event)"
        />
      </div>
      <div>
        <label class="filter__label tw-mt-2" for="date">Бюджет, руб</label>
        <div class="tw-flex tw-items-center tw-gap-2.5">
          <zem-input v-model="budget.from" class="tw-mt-0" type="number" @input="handlerBudget" />
          <MinusIcon class="tw-text-[#9BA6B2]" stroke-width="3" />
          <zem-input v-model="budget.to" class="tw-mt-0" type="number" @input="handlerBudget" />
        </div>
      </div>
      <div class="filter__period-block">
        <label class="filter__label tw-mt-2">Заказ создан</label>
        <date-picker
          v-model="selectedItems.orderCreated"
          :class="{'tw-text-neutral-700': selectedItems.orderCreated}"
          format="DD.MM.YYYY"
          locale="ru"
          placeholder="Выбрать период"
          range
          value-type="format"
          @change="handlerDate('created_at', $event)"
        ></date-picker>
      </div>
      <div class="filter__period-block">
        <label class="filter__label tw-mt-2">Заказ закрыт</label>
        <date-picker
          v-model="selectedItems.orderClosed"
          format="DD.MM.YYYY"
          locale="ru"
          placeholder="Выбрать период"
          range
          value-type="format"
          @change="handlerDate('finished_at', $event)"
        ></date-picker>
      </div>
      <div class="zem-dropdown mb-0">
        <label class="filter__label tw-mt-2" for="date">Регион</label>
        <ZemDropdownList
          v-model="selectedItems.region"
          :options="regionsList"
          class="mt-0"
          @change="handlerFilter('region_id', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ZemMultiSelect from '@/components/ui/ZemMultiSelect'
import ZemIcon from '@/components/ui/ZemIcon'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import ZemInput from '@/components/ui/ZemInput.vue'
import {MinusIcon} from 'vue-feather-icons'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import OrdersService from '@/services/orders.service'
import moment from 'moment'
import ProfileService from '@/services/profile.service'

export default {
  name: 'FilterObjectsForm',

  components: {
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ZemIcon,
    MinusIcon,
    DatePicker,
  },

  data() {
    return {
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      funnelsList: [],
      statusesList: [],
      regionsList: [],
      selectedItems: {
        funnel: {
          title: '',
          value: '',
        },
        status: {
          title: '',
          value: '',
        },
        orderCreated: '',
        orderClosed: '',
        region: {
          title: '',
          value: '',
        },
      },
      budget: {
        from: 0,
        to: 0,
      },
      filterData: {},
      timer: null,
    }
  },

  computed: {
    filter: {
      get() {
        return this.$store.state.orders.filter
      },
      set(value) {
        this.$store.commit('orders/filterMain', value)
      },
    },
  },

  mounted() {
    OrdersService.getFunnels(true).then(r => {
      this.funnelsList = r.data.data
    })
    OrdersService.getOrderStatuses().then(r => {
      this.statusesList = r.data.data.map(status => ({
        title: `${status.pipeline_id ? status.pipeline.data.name + ' - ' : ''}${status.name}`,
        value: status.id,
        parent: status.pipeline_id,
      }))
    })
    ProfileService.getRegionsList().then(r => {
      this.regionsList = r.data.data
    })
  },

  methods: {
    resetFilter() {
      this.$store.commit('orders/getSearch', '')
      this.selectedItems = {
        funnel: {
          title: '',
          value: '',
        },
        status: {
          title: '',
          value: '',
        },
        orderCreated: '',
        orderClosed: '',
        region: {
          title: '',
          value: '',
        },
      }
      this.$router.push({name: 'orders'})
      this.filterData = {}
      this.getFilter()
    },
    closeLeftSidebar() {
      this.$store.commit('sidebars/changeLeftSidebar', false)
    },
    handlerFilter(field, e) {
      const value = 'title' in e ? e.value : e
      this.filterData[field] = 'title' in e ? e.value : e
      if (!value) delete this.filterData[field]
      this.getFilter()
    },
    handlerBudget() {
      const {from, to} = this.budget
      if (Number(to)) {
        this.filterData['budget'] = `${from}-${to}`
      } else {
        this.filterData['budget'] = `${from}`
      }
      if (!Number(from) && !Number(to)) delete this.filterData['budget']
      this.getFilter()
    },
    handlerDate(field, e) {
      const startDate = moment(e[0], 'DD.MM.YYYY').format('YYYY-MM-DD')
      const endDate = moment(e[1], 'DD.MM.YYYY').format('YYYY-MM-DD')
      this.filterData[field] = `${startDate}|${endDate}`
      if (!e[0] && !e[1]) delete this.filterData[field]
      this.getFilter()
    },
    getFilter() {
      clearTimeout(this.timer)
      this.timer = null
      this.timer = setTimeout(() => {
        let data = ''
        let counter = 0
        for (let key in this.filterData) {
          data += key + ':' + this.filterData[key]
          counter++
          if (Object.keys(this.filterData).length !== counter) data += ';'
        }
        this.$store.commit('orders/filterMain', data)
        this.$emit('on-update-table')
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.filter {
  .mx-datepicker {
    width: 100%;
  }

  .mx-input-wrapper {
    .mx-input {
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      padding: 6px 6px 6px 8px;
      height: unset;
      border: 1px solid #f0f0f0;
      -webkit-box-shadow: unset;
      box-shadow: unset;
    }

    .mx-input:hover,
    .mx-input:focus {
      border-color: $color-accent;
    }

    .mx-input::-webkit-input-placeholder {
      color: #9ba6b3;
    }

    .mx-input::-moz-placeholder {
      color: #9ba6b3;
    }

    .mx-icon-calendar {
      color: #9ba6b3;
      font-size: 13px;
    }
  }
}
</style>

<style lang="scss" scoped>
.filter {
  display: block;
  padding: 20px 24px;
  min-width: 192px;

  &__btn-block {
    display: flex;
    justify-content: space-between;
  }

  &__btn-left {
    display: flex;
    align-items: center;

    &__label {
      font-weight: 700;
      font-size: 12px;
      line-height: 13px;
      color: $color-mine-shaft;
    }

    &__reset-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      color: $color-accent;
      text-align: right;
      flex: 1;
      margin-left: 8px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__label {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: $color-gull-gray;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 4px;
  }

  &__checkbox-block {
    margin-top: 8px;

    label:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__period-block {
    display: flex;
    flex-direction: column;
  }

  &__btn-period {
    width: 100%;
    padding: 5px 6px 5px 8px;
    border: 1px solid $color-catskill-grey;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    cursor: pointer;
    background-color: $color-white;
    color: $color-gull-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: $color-iron;
      border: 1px solid $color-iron;
      //color: $color-oslo-gray;
      opacity: 0.8;
    }
  }

  &__icon-period {
    width: 16px;
    height: 16px;
    background-image: url('../../assets/icons/calendar.svg');
  }

  &__dropdown {
    margin-top: 8px;
  }
}

@media screen and (max-width: 1440px) {
  .filter {
    padding: 20px;
    min-width: 152px;
  }
}
</style>
